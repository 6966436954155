import React from 'react';
import { IQuiz } from './types';

import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Button from '@material-ui/core/Button';
import {makeStyles} from '@material-ui/core/styles';


const useStyles = makeStyles(theme => ({
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    paper: {
        marginTop: theme.spacing(2),
        display: 'flex',
        flexDirection: 'column',
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
}));

export default function TitleQuestion({quiz}: {quiz: IQuiz}) {
    const classes = useStyles();
    const currentQuestion = quiz.flow[quiz.currentQuestionIndex];
    let isTextValid = quiz.isValueValid(undefined, true);
    
    return (<React.Fragment>
        <div className="flex-scrollable">
            <Container maxWidth="xs">
                <div className={classes.paper}>
                <Typography component="h1" variant="h5">
                    {currentQuestion.title}
                </Typography>

                <Typography component="h3" variant="subtitle1">
                    {currentQuestion.hint}
                </Typography>

                {currentQuestion.type === 'input' && <form className={classes.form} noValidate onSubmit={e => {
                    e.preventDefault();
                    if(quiz.isValueValid()){
                        quiz.nextQuestion();
                    }
                    return false;
                }}>
                    <TextField
                        type={currentQuestion.htmlInputType || 'text'}
                        label={currentQuestion.title}
                        placeholder={currentQuestion.placeholder}
                        value={currentQuestion.value == null ? '' : currentQuestion.value}
                        fullWidth
                        onChange={(event) => quiz.storeValue(quiz.currentQuestionIndex, event.target.value)}
                        margin="normal"
                        variant="outlined"
                        error={!isTextValid}
                        autoFocus
                    />
                    {quiz.isValueValid() && <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                        className={classes.submit}
                    >
                    {currentQuestion.button || 'Next'}
                    </Button>}
                </form>}
                
                </div>
            </Container>
        </div>
    </React.Fragment>);
}