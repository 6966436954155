import React from 'react';
import { IQuiz } from './types';

import Typography from '@material-ui/core/Typography';
import FormGroup from '@material-ui/core/FormGroup';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import Checkbox from '@material-ui/core/Checkbox';
import Container from '@material-ui/core/Container';
import Button from '@material-ui/core/Button';
import {makeStyles} from '@material-ui/core/styles';


const useStyles = makeStyles(theme => ({
    submit: {
      margin: theme.spacing(3, 0, 2),
    },
    paper: {
        marginTop: theme.spacing(2),
        display: 'flex',
        flexDirection: 'column',
    },
  }));

export default function CheckboxQuestion({quiz}: {quiz: IQuiz}) {
    const classes = useStyles();
    const currentQuestion = quiz.flow[quiz.currentQuestionIndex];
    const options = currentQuestion.options || [];
    const selectedCheckboxes = currentQuestion.value || []; //options.map(o => false);
    const isRadio = currentQuestion.minSelection === 1 && currentQuestion.maxSelection === 1;

    const handleCheckboxChange = (optionIndex: number) => {
        return (event: any) => {
            let newSelectedCheckboxes : string[] = selectedCheckboxes.concat();
            if(event.target.checked){
                newSelectedCheckboxes.push(options[optionIndex].value);
            }else{
                newSelectedCheckboxes = newSelectedCheckboxes.filter((v) => v !== options[optionIndex].value);
            }

            quiz.storeValue(quiz.currentQuestionIndex, newSelectedCheckboxes);
        }
    }

    const handleRadioChange = (event: any) => {
        quiz.storeValue(quiz.currentQuestionIndex, [event.target.value]);
    }

    return (<React.Fragment>
        <div className="flex-scrollable">
            <Container maxWidth="xs">
                <div className={classes.paper}>
                    <Typography component="h1" variant="h5">
                        {currentQuestion.title}
                    </Typography>

                    <Typography component="h3" variant="subtitle1">
                        {currentQuestion.hint}
                    </Typography>
                    {isRadio && <RadioGroup onChange={handleRadioChange} value={selectedCheckboxes[0]}>
                        {options.map(({value, label}, i) => <FormControlLabel
                            key={i}
                            control={<Radio value={value} />}
                            label={label}
                        />)}
                    </RadioGroup>}
                    {!isRadio && <FormGroup>
                        {options.map(({value, label}, i) => <FormControlLabel
                            key={i}
                            control={<Checkbox checked={selectedCheckboxes.indexOf(value) > -1} onChange={handleCheckboxChange(i)} value={value} />}
                            label={label}
                        />)}
                    </FormGroup>}
                    {quiz.isValueValid() && <Button
                        fullWidth
                        variant="contained"
                        color="primary"
                        className={classes.submit}
                        onClick={() => quiz.nextQuestion()}
                    >
                        {currentQuestion.button || 'Next'}
                    </Button>}
                </div>
            </Container>
        </div>
    </React.Fragment>);
}