import React, { useState, useEffect } from 'react';
import Questions from './Questions';
import {flow as jsonFlow} from './rms.json';
import axios from 'axios';

import {IFlow, IQuiz, IFormValues} from './types';
import { createMuiTheme } from '@material-ui/core';
import { ThemeProvider } from '@material-ui/styles';

function dataURItoBlob(dataURI: string) {
    // convert base64/URLEncoded data component to raw binary data held in a string
    var byteString;
    if (dataURI.split(',')[0].indexOf('base64') >= 0)
        byteString = atob(dataURI.split(',')[1]);
    else
        byteString = unescape(dataURI.split(',')[1]);

    // separate out the mime component
    var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

    // write the bytes of the string to a typed array
    var ia = new Uint8Array(byteString.length);
    for (var i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
    }

    return new Blob([ia], {type: mimeString});
}

const credentials = {
    username: 'rmsauditgeneric',
    password: 'na6m8BWYPE6M8x'
}

const theme = createMuiTheme({
    palette: {
        primary: {
            main: '#343434',
        },
        secondary: {
            main: '#1976d2',
        },
    },
});

export default function QuestionWrapper ({history, match}: any) {
    const [flow, setFlow] = useState(jsonFlow as IFlow[]);
    const [formValues] = useState({} as IFormValues);
    const [formFillPath, setFormFillPath] = useState([0]);
    const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
    const [navigationDisabled, setNavigationDisabled] = useState(false);
    const [submitStatus, setSubmitStatus] = useState('Please wait...');

    const storeValue = (i: number, value: any) => {
        const newFlow = [...flow];
        const question = newFlow[i];
        newFlow[i] = {...question, value};
        setFlow(newFlow);
    }

    const stepToIndex = (step: string) => {
        for(let i = 0; i < flow.length; i+=1){
            if(flow[i].step === step){
                return i;
            }
        }
    }

    const conditionalResponse = (question: IFlow) => {
        const {conditionalStep, conditionalOperator, conditionalAcceptedValues} = question;
        if(conditionalStep == null || conditionalAcceptedValues == null){return true;}

        const stepIndex = stepToIndex(conditionalStep);
        if(stepIndex === undefined){return true;}

        let actualValue = flow[stepIndex].value;

        if(conditionalOperator === 'eq'){
            if(actualValue === undefined){
                actualValue = "";
            }else if(Array.isArray(actualValue)){
                actualValue = actualValue.join(',');
            }
            return conditionalAcceptedValues.indexOf(actualValue) !== -1;
        }else if(conditionalOperator === 'neq'){
            if(actualValue === undefined){
                actualValue = "";
            }else if(Array.isArray(actualValue)){
                actualValue = actualValue.join(',');
            }
            return conditionalAcceptedValues.indexOf(actualValue) === -1;
        }else if(conditionalOperator === 'has' && Array.isArray(actualValue)){
            return conditionalAcceptedValues.every(testValue => actualValue.includes(testValue));
        }else if(conditionalOperator === 'nhas' && Array.isArray(actualValue)){
            return !conditionalAcceptedValues.every(testValue => actualValue.includes(testValue));
        }
    }
    
    const checkJump = (question: IFlow) => {
        if(question.type === 'jump'){
            if(conditionalResponse(question)){
                nextQuestion(question.jumpToStep);
            }else{
                nextQuestion(currentQuestionIndex + 2);
            }
        }
    }

    const nextQuestion = (step?: string|number) => {
        if(!isValueValid()){return;}

        // goes to the next question, or to a specific step if specified
        let nextQuestionIndex = currentQuestionIndex+1;

        if(step && typeof step === 'string'){
            const index = stepToIndex(step);
            if(index != null){
                nextQuestionIndex = index;
            }
            // TODO: handle no case
        }else if(step && typeof step === 'number'){
            nextQuestionIndex = step;
        }

        if(flow[nextQuestionIndex] == null){return;}

        if(flow[nextQuestionIndex].type === 'jump'){
            checkJump(flow[nextQuestionIndex]);
            return;
        }

        const path = [...formFillPath, nextQuestionIndex];

        setCurrentQuestionIndex(nextQuestionIndex);
        setFormFillPath(path);

        history.push(`/audit/new/questions/${path.join(',')}`);
    }

    const prevQuestion = () => {
        const path = [...formFillPath];
        const prevQuestionIndex = path.pop();

        if(prevQuestionIndex != null && path.length > 0){
            setCurrentQuestionIndex(path[path.length-1]);
            setFormFillPath(path);
            history.push(`/audit/new/questions/${path.join(',')}`)
        }
    }

    useEffect(() => {
        const pathFromUrl = (match.params.path||'0');
        const currentPath = formFillPath.join(',');

        if(pathFromUrl !== currentPath && !navigationDisabled){
            const path = pathFromUrl.split(',').map((n: string) => parseInt(n));
            setCurrentQuestionIndex(path[path.length-1]);
            setFormFillPath(path);
        }
    }, [match.params.path, formFillPath, navigationDisabled]);

    // useEffect(() => {
    //     checkJump();
    // }, [currentQuestionIndex])

    const isValueValid = (index?: number, allowBlankState = false) => {
        const currentQuestion = flow[index||currentQuestionIndex];

        if(currentQuestion.type === 'input' && currentQuestion.pattern != null){
            const comparisonValue = (currentQuestion.value == null ? '' : currentQuestion.value);
            if(!allowBlankState || comparisonValue.length > 0){
                const regex = new RegExp(currentQuestion.pattern);
                return regex.test(comparisonValue);
            }
        }

        if(currentQuestion.type === 'checkbox'){
            const count = (currentQuestion.value||[]).length;
            const min = currentQuestion.minSelection||0;
            const max = (currentQuestion.maxSelection != null ? currentQuestion.maxSelection : Infinity);
            return count >= min && count <= max;
        }

        if(currentQuestion.type === 'camera'){
            const photos = currentQuestion.value||[];
            const minPhotos = currentQuestion.minSelection || 0;
            const maxPhotos = currentQuestion.maxSelection;
            return (photos.length >= minPhotos && (!maxPhotos || photos.length <= maxPhotos));
        }

        return true;
    }

    const submit = async () => {
        setNavigationDisabled(true);
        try{
            setSubmitStatus('Connecting to server...');
            let {data: response} = await axios.post('https://api.datascout.com.au/rest/datapoint/', {
                json: {
                    treasureList: {url: 'https://api.datascout.com.au/rest/treasurelist/1/'},
                    createdAt: new Date(),
                    updatedAt: new Date(),
                    responses: {},
                    commonProperties: {},
                    formState: {
                        formFillPath,
                        selectedQuestionIndex: currentQuestionIndex
                    },
                },
                quest: 'https://api.datascout.com.au/rest/quest/1/'
            }, {
                auth: credentials,
            });

            const dataPointUrl = response.url;

            const responses : IFormValues = {};
            // recurse through and upload photos of any
            for(const question of flow){
                if(question.value != null && question.step != null){
                    responses[question.step] = question.value;

                    if(question.type === 'camera'){
                        responses[question.step] = responses[question.step].map((photo: any) => photo.photoUuid)
                        const photos = (question.value||[]);

                        let i = 0;
                        for(const photo of photos){
                            i++;
                            setSubmitStatus(`Uploading photos: ${i} of ${photos.length}`);
                            const blob = dataURItoBlob(photo.uri);
                            const data = new FormData();
                            data.append('data_point', dataPointUrl);
                            data.append('status',  'http://api.datascout.com.au/rest/fieldstatus/1/');
                            data.append('field', photo.questFieldOfTypePhoto);
                            data.append('value', blob, photo.generatedFileName);
                            await axios.post('https://api.datascout.com.au/rest/datapointphotovalue/', data, {
                                auth: credentials,
                                headers: {
                                    'Content-Type': 'multipart/form-data',
                                }
                            })
                        }
                    }
                }
            }

            setSubmitStatus(`Sending form responses`);

            await axios.put(dataPointUrl.replace(/^http:/, 'https:'), {
                json: {
                    updatedAt: new Date(),
                    createdAt: new Date(),
                    responses
                },
                quest: 'https://api.datascout.com.au/rest/quest/1/'
            }, {
                auth: credentials
            });

            setSubmitStatus(`Success!`);
        }catch(e){
            setNavigationDisabled(false);
            throw e;
        }
    }

    const quiz : IQuiz = {
        storeValue,
        flow,
        formValues,

        formFillPath,
        currentQuestionIndex,

        nextQuestion,
        prevQuestion,
        isValueValid,
        submit,

        navigationDisabled,
        setNavigationDisabled,
        submitStatus
    }

    return <ThemeProvider theme={theme}>
        <Questions quiz={quiz} />
    </ThemeProvider>;
}
