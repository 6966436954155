import React from 'react';
import { IQuiz } from './types';

import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import MobileStepper from '@material-ui/core/MobileStepper';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import { makeStyles, useTheme } from '@material-ui/core/styles';

import PhotoSelectableQuestion from './PhotoSelectableQuestion';
import LocationQuestion from './LocationQuestion';
import CheckboxQuestion from './CheckboxQuestion';
import TitleQuestion from './TitleQuestion';
import InputQuestion from './InputQuestion';
import Review from './Review';

const useStyles = makeStyles(theme => ({
  '@global': {
    body: {
      backgroundColor: theme.palette.common.white,
    },
  },
  paper: {
    marginTop: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  progress: {
    // maxWidth: 400,
    flexGrow: 1,
  },
  title: {
    flexGrow: 1,
  },
  logo: {
    height: 32,
    width: 32,
    marginRight: 10
  },
}));

export default function Questions({quiz}: {quiz: IQuiz}) {
  const classes = useStyles();
  const theme = useTheme();
  const currentQuestion = quiz.flow[quiz.currentQuestionIndex];

  return (
    <React.Fragment>
        <AppBar position="static">
          <Toolbar>
            <img src="/datascout.png" alt="logo" className={classes.logo} />
            <Typography variant="h6" className={classes.title}>
              Datascout
            </Typography>
          </Toolbar>
        </AppBar>
        <main style={{flex: 1, flexDirection: 'column', display: 'flex', minHeight: 0}} key={quiz.currentQuestionIndex}>
          <CssBaseline />
          {currentQuestion.type === 'title' && <TitleQuestion quiz={quiz} />}
          {currentQuestion.type === 'input' && <InputQuestion quiz={quiz} />}
          {currentQuestion.type === 'checkbox' && <CheckboxQuestion quiz={quiz} />}
          {currentQuestion.type === 'location' && <LocationQuestion quiz={quiz} />}
          {currentQuestion.type === 'camera' && <PhotoSelectableQuestion quiz={quiz} />}
          {currentQuestion.type === 'submit' && <Review quiz={quiz} />}
        </main>
        <div>
        <MobileStepper
          variant="progress"
          steps={quiz.flow.length}
          position="static"
          activeStep={quiz.currentQuestionIndex}
          className={classes.progress}
          backButton={
            <Button style={{
              visibility: quiz.formFillPath.length <= 1 ? 'hidden' : 'visible'
            }} size="small" onClick={() => quiz.prevQuestion()} disabled={quiz.navigationDisabled || quiz.formFillPath.length <= 1}>
              {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
              Back
            </Button>
          }
          nextButton={
            <Button style={{
              visibility: quiz.currentQuestionIndex === quiz.flow.length-1 ? 'hidden' : 'visible'
            }} size="small" onClick={() => quiz.nextQuestion()} disabled={quiz.navigationDisabled || !quiz.isValueValid() || quiz.currentQuestionIndex === quiz.flow.length-1}>
              Next
              {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
            </Button>
          }
        />
      </div>
    </React.Fragment>
  );
}