import React from 'react';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import {IQuiz} from './types';

const useStyles = makeStyles(theme => ({
  '@global': {
    body: {
      backgroundColor: theme.palette.common.white,
    },
  },
  paper: {
    marginTop: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  logo: {
      width: '35%',
      minHeight: 1,
      margin: theme.spacing(2),
  },
  textCenter: {
      textAlign: 'center'
  },
}));

export default function Completed({quiz}: {quiz: IQuiz}) {
    const classes = useStyles();

    return <Container maxWidth="xs" className={classes.paper}>
        <img src="/datascout.png" alt="Datascout logo"  className={classes.logo} />
        <Typography component="h1" variant="h5" className={classes.textCenter}>
            Thank you for submitting your feedback
        </Typography>
        <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            onClick={() => {
                window.location.href = '/'
            }}
        >
            Submit another report
        </Button>
    </Container>
}