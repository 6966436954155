import React from 'react';
import { BrowserRouter as Router, Route, Redirect } from "react-router-dom";
import QuestionWrapper from './screens/NewAudit/QuestionWrapper';

const App: React.FC = () => {
  return (
    <Router>
      <Route path="/audit/new/questions/:path?" component={QuestionWrapper} />
      <Redirect exact path="/" to="/audit/new/questions" />
    </Router>
  );
}

export default App;