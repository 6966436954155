import React, { useState } from 'react';
import {IQuiz, IFlow} from './types';

import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import { makeStyles } from '@material-ui/core/styles';

import Completed from './Completed';
import Submitting from './Submitting';
import { ReviewLocation } from './ReviewLocation';

const useStyles = makeStyles(theme => ({
  '@global': {
    body: {
      backgroundColor: theme.palette.common.white,
    },
  },
  paper: {
    marginTop: theme.spacing(4),
    display: 'flex',
    flexDirection: 'column',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  error: {
    backgroundColor: theme.palette.error.dark,
  },
}));

export default function Review({quiz}: {quiz: IQuiz}) {
    const classes = useStyles();
    const [completed, setCompleted] = useState(false);
    const [sending, setSending] = useState(false);
    const [error, setError] = useState('');
    const submit = async () => {
        if(sending){return;}
        setSending(true);
        try{
            await quiz.submit();
            setCompleted(true);    
        }catch(e){
            if(e.message){
                setError(`${e.message}. Please try again later.  If the problem persists, please contact support.`);
            }else{
                setError('Unexpected error. Please try again later.  If the problem persists, please contact support.')
            }
        }
        setSending(false);
    }

    const standardQuestionLayouts = ['title', 'checkbox', 'input', 'camera', 'location'];
    const isSupportedPreviewQuestion = (question: IFlow) => {
        return standardQuestionLayouts.includes(question.type);
    }

    if(completed){
        return <Completed quiz={quiz} />
    }

    if(sending){
        return <Submitting quiz={quiz} />
    }

    return (<div className="flex-scrollable">
        <Container maxWidth="xs">
            <div className={classes.paper}>
                <Typography component="h1" variant="h5">
                Review your response
                </Typography>
            </div>
            {error && <div className={classes.paper}>
                <SnackbarContent
                    className={classes.error}
                    message={error}
                />
            </div>}
            <div className={classes.paper}>
                {quiz.flow.map((question, i) => (isSupportedPreviewQuestion(question) && quiz.formFillPath.indexOf(i) > -1 && <Card key={i}>
                    <CardContent>
                        <Typography component="h2" variant="h6">
                            {question.title}
                        </Typography>
                        {question.type === 'input' && <Typography>
                            {question.value}
                        </Typography>}
                        {question.type === 'checkbox' && <Typography>
                            <ul>
                                {(question.value as string[]||[]).map((v: string, i: number) => <li key={i}>{v}</li>)}
                            </ul>
                        </Typography>}
                        {question.type === 'location' && question.value && <Typography>
                            <ReviewLocation latitude={question.value.latitude} longitude={question.value.longitude} />
                        </Typography>}
                        {question.type === 'camera' && (question.value||[]).map((photo: any, i: number) => <img src={photo.uri} alt={`Preview of a photograph taken by user`} style={{maxWidth: '100%'}} key={i} />)}
                    </CardContent>
                </Card>))}

                <div className={classes.form}>
                    <Button
                        fullWidth
                        variant="contained"
                        color="primary"
                        className={classes.submit}
                        onClick={() => submit()}
                    >
                        Submit
                    </Button>
                    {error}
                </div>

            </div>
            </Container>
        </div>
    );
}