import React from 'react';
import {IQuiz} from './types';

import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  '@global': {
    body: {
      backgroundColor: theme.palette.common.white,
    },
  },
  paper: {
    marginTop: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  logo: {
      width: '35%',
      minHeight: 1,
      margin: theme.spacing(2),
  },
  textCenter: {
      textAlign: 'center',
      margin: theme.spacing(2),
  },
}));

export default function Submitting({quiz}: {quiz: IQuiz}) {
    const classes = useStyles();

    return <Container maxWidth="xs" className={classes.paper}>
        <img src="/datascout.png" alt="Datascout logo" className={classes.logo} />
        <Typography component="h1" variant="h5" className={classes.textCenter}>
            {quiz.submitStatus}
        </Typography>
        <CircularProgress />
    </Container>
}