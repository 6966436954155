import React, { useState, useRef } from 'react';
import { IQuiz } from './types';
import ReactMapGL, { Marker} from 'react-map-gl';
import LocationSearching from '@material-ui/icons/LocationSearching';
import MyLocation from '@material-ui/icons/MyLocation';
import 'mapbox-gl/dist/mapbox-gl.css';
import Fab from '@material-ui/core/Fab';
import 'react-map-gl-geocoder/dist/mapbox-gl-geocoder.css';
import Geocoder from 'react-map-gl-geocoder';
import { MAPBOX_TOKEN } from './consts';
import Container from '@material-ui/core/Container';
import {makeStyles} from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

const getPosition = async (options?: PositionOptions) : Promise<Position> => {
    if ("geolocation" in navigator) {
        return new Promise(function (resolve, reject) {
            navigator.geolocation.getCurrentPosition(resolve, reject, options);
        });
    } else {
        throw new Error('Your browser or device does not support geolocation.')
    }
}

const useStyles = makeStyles(theme => ({
    paper: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
        display: 'flex',
        flexDirection: 'column',
        textAlign: 'center'
    },
}));

export default function LocationQuestion({quiz}: {quiz: IQuiz}) {
    const classes = useStyles();

    const currentQuestion = quiz.flow[quiz.currentQuestionIndex];
    const [viewport, setViewport] = useState({
        latitude: (currentQuestion.value && currentQuestion.value.latitude) || -33.86257,
        longitude: (currentQuestion.value && currentQuestion.value.longitude) || 151.2104,
        zoom: 16
    });

    const setLocation = (viewport: any) => {
        setViewport(viewport);
        quiz.storeValue(quiz.currentQuestionIndex, {
            latitude: viewport.latitude,
            longitude: viewport.longitude
        });
    }

    const handleGeolocation = async () => {
        try{
            const pos = await getPosition();
            setViewport({
                latitude: pos.coords.latitude,
                longitude: pos.coords.longitude,
                zoom: (viewport.zoom >= 16 ? viewport.zoom : 16)
            });
        }catch(e){
            alert('Unable to get your location.  Please pan map to select location.');
        }
    }

    const mapRef = useRef(null);

    return (<>
        {(currentQuestion.title || currentQuestion.hint) && <Container maxWidth="xs">
            <div className={classes.paper}>
                {currentQuestion.title &&<Typography component="h1" variant="h5">
                    {currentQuestion.title}
                </Typography>}

                {currentQuestion.hint && <Typography component="h3" variant="subtitle1">
                    {currentQuestion.hint}
                </Typography>}
            </div>
        </Container>}
        <ReactMapGL
            ref={mapRef}
            width={"100%"}
            height={"100%"}
            mapboxApiAccessToken={MAPBOX_TOKEN}
            {...viewport}
            onViewportChange={({width, height, ...viewport}: any) => setLocation(viewport)}
        >
                <Geocoder
                    mapRef={mapRef}
                    onViewportChange={(viewport: any) => setLocation(viewport)}
                    mapboxApiAccessToken={MAPBOX_TOKEN}
                    countries="au"
                    // https://docs.mapbox.com/mapbox-gl-js/example/mapbox-gl-geocoder-limit-region/ actually has NSW coords...
                    // bbox={[139.965, -38.030, 155.258, -27.839]}
                    // from https://boundingbox.klokantech.com/ (search for nsw and use what it has)
                    bbox={[140.9993, -37.528, 159.339, -28.1415]}
                    proximity={viewport} // seems to prefer bendigo over sydney without this
                />
                
                <Marker latitude={viewport.latitude} longitude={viewport.longitude} offsetLeft={-20} offsetTop={-20}>
                    <LocationSearching style={{height: 40, width: 40}} />
                </Marker>

                <Fab style={{
                    position: 'absolute',
                    bottom: 30,
                    right: 10
                }} aria-label="Get geolocation" onClick={handleGeolocation} color="secondary">
                    <MyLocation />
                </Fab>
            </ReactMapGL>
        </>
  );
}
