import React, { useState, useEffect } from 'react';
import mbxGeocoding from "@mapbox/mapbox-sdk/services/geocoding";
import { MAPBOX_TOKEN } from "./consts";

const geocodingClient = mbxGeocoding({ accessToken: MAPBOX_TOKEN });
export const ReviewLocation = ({latitude, longitude}: any) => {
    const [address, setAddress] = useState('Unknown address');

    useEffect(() => {
        geocodingClient.reverseGeocode({
            query: [longitude, latitude],
            mode: 'mapbox.places'
        })
        .send()
        .then(response => {
            // GeoJSON document with geocoding matches
            const match = response.body;
            if(match && match.features && match.features.length > 0){
                // first feature
                const feature = match.features[0];
                setAddress(feature.place_name);
            }
        });
    }, [latitude, longitude]);

    return <div>
        <b>Lat/Lon</b>: {latitude.toFixed(4)},{longitude.toFixed(4)}<br />
        <b>Approximate address</b>:<br />{address}
    </div>
}