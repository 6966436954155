import React from 'react';
import { IQuiz } from './types';

import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Button from '@material-ui/core/Button';
import {makeStyles} from '@material-ui/core/styles';
import ReactMarkdown from 'react-markdown';

const useStyles = makeStyles(theme => ({
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    paper: {
        marginTop: theme.spacing(2),
        display: 'flex',
        flexDirection: 'column',
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    titleImage: {
      width: '30%',
      minHeight: 1,
      marginTop: 15,
      marginBottom: 15
    }
}));

export default function TitleQuestion({quiz}: {quiz: IQuiz}) {
    const classes = useStyles();
    const currentQuestion = quiz.flow[quiz.currentQuestionIndex];
    
      return (<React.Fragment>
            <div className="flex-scrollable">
            <Container maxWidth="xs">
            {currentQuestion.imageUrl && <div className={classes.paper}><img alt="" src={currentQuestion.imageUrl} className={classes.titleImage} /></div>}
              <div className={classes.paper}>
                <Typography component="h1" variant="h5">
                  {currentQuestion.title}
                </Typography>

                
                {currentQuestion.markdown ? <Typography component="div" variant="subtitle1"><ReactMarkdown source={currentQuestion.hint} /></Typography> : <Typography component="h3" variant="subtitle1">currentQuestion.hint</Typography>}

                {currentQuestion.type === 'title' && <div className={classes.form}>
                  <Button
                    fullWidth
                    variant="contained"
                    color="primary"
                    className={classes.submit}
                    onClick={() => quiz.nextQuestion()}
                  >
                    {currentQuestion.button || 'Next'}
                  </Button>
                </div>}
                
              </div>
            </Container>
        </div>
    </React.Fragment>);
}