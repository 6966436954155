import React from 'react';
import { IQuiz } from './types';
import uuidv4 from 'uuid/v4';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardMedia from '@material-ui/core/CardMedia';
import CardActions from '@material-ui/core/CardActions';
import { makeStyles } from '@material-ui/core/styles';

interface IPhoto {
    isPhoto: boolean;
    photoUuid: string;
    uri: string;
    questFieldOfTypePhoto: string;
    generatedFileName: string;
}

const useStyles = makeStyles(theme => ({
  '@global': {
    body: {
      backgroundColor: theme.palette.common.white,
    },
  },
  paper: {
    marginTop: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    // margin: theme.spacing(3, 0, 2),
  },
  card: {
    marginBottom: theme.spacing(1),
  },
  media: {
    minHeight: 140,
  },
  textCenter: {
      textAlign: 'center'
  }
}));

export default function PhotoSelectableQuestion({quiz}: {quiz: IQuiz}) {
  const currentQuestion = quiz.flow[quiz.currentQuestionIndex];

  const classes = useStyles();
  const photos = (currentQuestion.value||[]);

  const fileToBase64 = (file: File) : Promise<string> => {
      return new Promise(resolve => {
        const reader = new FileReader();
        reader.addEventListener("load", function () {
            resolve(this.result as string);
        }, false);

        reader.readAsDataURL(file);
    });
  }

  const handleCapture = async ({target}: React.ChangeEvent<HTMLInputElement>) => {
        if(target.files && currentQuestion.questFieldOfTypePhoto){
            const files = [...target.files as any] as File[];
            const photos : IPhoto[] = (currentQuestion.value||[]);
            for(const file of files){
                const dataUri = await fileToBase64(file);
                const photoUuid = `datapointphoto:${uuidv4()}`;
                const photo : IPhoto = {isPhoto: true, photoUuid, uri: dataUri, questFieldOfTypePhoto: currentQuestion.questFieldOfTypePhoto, generatedFileName: `${photoUuid.replace(':', '-')}.jpg`}
                photos.push(photo);
            }
            quiz.storeValue(quiz.currentQuestionIndex, photos)
        }
  }

    const deletePhoto = (i: number) => {
        const newPhotos = photos.concat();
        newPhotos.splice(i, 1);
        quiz.storeValue(quiz.currentQuestionIndex, newPhotos)
    }

  return (<React.Fragment>
      <div className="flex-scrollable">
            <Container maxWidth="xs">
                {photos.length > 0 ? <div className={classes.paper}>
                    {/* <img src={photo.uri} alt={`Preview of a photograph taken by user`} style={{maxWidth: '100%'}} key={i} /> */}
                    {photos.map((photo: IPhoto, i: number) => 
                        <Card className={classes.card} key={i}>
                            <CardActionArea>
                                <CardMedia
                                    className={classes.media}
                                    image={photo.uri}
                                    component="img"
                                    title="Preview of a photograph taken by the user"
                                />
                            </CardActionArea>
                            <CardActions>
                                <Button size="small" color="primary" onClick={() => {deletePhoto(i)}}>
                                    Delete
                                </Button>
                            </CardActions>
                        </Card>
                        
                    )}
                </div> : <div className={classes.paper}>
                    <Typography component="h1" variant="h5">
                        {currentQuestion.questionNumber} {currentQuestion.title}
                    </Typography>
                    <Typography component="h2" variant="subtitle1">
                        {currentQuestion.hint}
                    </Typography>
                </div>}
            </Container>
        </div>
        <Container maxWidth="xs">
            <div className={classes.paper}>
                <ButtonGroup fullWidth aria-label="full width outlined button group">
                    <Button
                        variant="contained"
                        component="label"
                        color="primary"
                        className={classes.submit}
                    >
                        Take photo
                        <input
                            type="file"
                            style={{ display: "none" }}
                            accept="image/*" 
                            capture="environment"
                            multiple
                            onChange={handleCapture}
                        />
                    </Button>
                    <Button
                        variant="contained"
                        component="label"
                        color="primary"
                        className={classes.submit}
                    >
                        Gallery
                        <input
                            type="file"
                            style={{ display: "none" }}
                            accept="image/*"
                            multiple
                            onChange={handleCapture}
                        />
                    </Button>
                </ButtonGroup>
                <Typography variant="body1" className={classes.textCenter}>
                    {photos.length} of {currentQuestion.maxSelection != null ? currentQuestion.maxSelection : 'unlimited'} photos
                </Typography>
                {/* <Button
                    variant="contained"
                    component="label"
                    fullWidth
                    color="primary"
                    className={classes.submit}
                >
                    Take photo
                    <input
                        type="file"
                        style={{ display: "none" }}
                        accept="image/*" 
                        capture
                        multiple
                        onChange={handleCapture}
                    />
                </Button>
                <Button
                    variant="contained"
                    component="label"
                    fullWidth
                    color="primary"
                    className={classes.submit}
                >
                    Select from gallery
                    <input
                        type="file"
                        style={{ display: "none" }}
                        accept="image/*"
                        multiple
                        onChange={handleCapture}
                    />
                </Button> */}
            </div>
        </Container>
    </React.Fragment>
  );
}